import { IVideoPlayer } from "interfaces/VideoStreamingInterface";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";
import Loading from "components/loading/loading.component";

const VideoPlayer: React.FC<IVideoPlayer> = ({
  video_url,
  thumbnail,
  className,
  videoStatus,
  onStart,
  onEnd,
  onProgress,
  onPaused,
  onSeek,
  onError,
  onBuffer
}) => {
  const [videoPlayerStatus, setVideoPlayerStatus] = useState<{
    playing: boolean;
    visible: boolean;
    alreadyStarted: boolean;
    buffering?: boolean;
    preloaded?: boolean;
  }>({
    playing: false,
    visible: false,
    alreadyStarted: false,
    buffering: false, // Now we start with no buffering
    preloaded: false, // Track if 15% is preloaded
  });

  const [buffered, setBuffered] = useState(0);  // Store buffered percentage
  const [played, setPlayed] = useState(0);  // Store played percentage
const [totalDuration,setDuration]= useState(0)
  const videoRef = useRef<ReactPlayer>(null); // Ref for ReactPlayer instance

  useEffect(() => {
    if (video_url) {
      setVideoPlayerStatus((prevData: any) => ({
        ...prevData,
        playing: false,
        buffering: false,  // Ensure buffering is off before play starts
        preloaded: false,
      }));
    }
  }, [video_url]);

  // useEffect(() => {
  //   if (Hls.isSupported() && videoRef.current?.getInternalPlayer()) {
  //     const hlsInstance = new Hls();
  //     hlsInstance.loadSource(video_url);
  //     hlsInstance.attachMedia(videoRef.current.getInternalPlayer() as HTMLMediaElement);

  //     hlsInstance.on(Hls.Events.MANIFEST_PARSED, () => {



  //       console.log( hlsInstance.levels,"hls levels")
  //       const defaultLevel = hlsInstance.levels.findIndex((level) => level.height === 480);
  //       if (defaultLevel !== -1) {
  //         hlsInstance.currentLevel = defaultLevel;
  //       }
  //     });

  //     setHls(hlsInstance);
  //   }
  //   return () => {
  //     hls?.destroy();
  //   };
  // }, [video_url]);
  const control=  videoRef?.current?.getInternalPlayer()

  const handleProgress = (state: { played: number,playedSeconds:number, loaded: number ,loadedSeconds:number}) => {
  // Set buffered percentage

if(buffered>=played&&videoPlayerStatus.preloaded&&!videoPlayerStatus.alreadyStarted){
  setVideoPlayerStatus((prevData) => ({ ...prevData,playing:!prevData.playing, buffering: false, preloaded: true }));

  // control?.play()

}

    // Check if at least 15% of the video is buffered
    if (state.loadedSeconds >= 10 && !videoPlayerStatus.preloaded) {
      setVideoPlayerStatus((prevData) => ({ ...prevData,playing:true, buffering: false, preloaded: true }));
 
console.log('now play')
    
      // control?.play()
    }
    setPlayed(state.playedSeconds * 100/totalDuration); // Set played percentage
    setBuffered(state.loadedSeconds * 100/totalDuration); 
    onProgress(state);
  };

//   const handleSeek = async(e: React.MouseEvent<HTMLDivElement>) => {
//     const seekBar = e.currentTarget;
//     const seekPosition = (e.nativeEvent.offsetX / seekBar.clientWidth) * 100;
//     const newSeekTime = (seekPosition / 100) * videoRef.current!.getDuration();
//     setVideoPlayerStatus((prevData) => ({
//       ...prevData,
//       buffering: true,  // Show loading when play starts
//       alreadyStarted: true,
//     }));
//  videoRef.current!.seekTo(newSeekTime);

//   };

  const handlePlayClick = () => {
    // Show loading after the play button is clicked
    if(    videoPlayerStatus.playing    ){
      setVideoPlayerStatus((prevData) => ({
        ...prevData,
        playing: false,
        alreadyStarted:true,

        buffering: false,  // Show loading when play starts
      }));
    }else{
   setVideoPlayerStatus((prevData) => ({
      ...prevData,
      playing: true,
      alreadyStarted:true,
      buffering: true,  // Show loading when play starts
    }));
    }
 
  };
  return (
    <div className="relative" key={videoStatus}>
      {/* Show loading only when buffering after play */}
      {videoPlayerStatus.buffering && (
        <div className="absolute inset-0 flex justify-center items-center">
          <div className="loader" style={{color:'white'}}><Loading /></div> {/* Buffering Indicator */}
        </div>
      )}

      <ReactPlayer
        key={videoStatus}
        className={`flex justify-center !rounded-lg ${className} w-auto h-auto`}
        url={video_url}
        config={{ file: { attributes: { controlsList: "nodownload", preload: 'auto', disablePictureInPicture:true,}} }}
        light={(!videoPlayerStatus.playing && !videoPlayerStatus.alreadyStarted) && thumbnail as string}
        playing={videoPlayerStatus.playing}
        controls={videoStatus === 'completed' ? true : false}
        width={"253px"}
        // progressInterval={1000}
        height={"450px"}
        onReady={()=> { 
          if(!videoPlayerStatus.alreadyStarted){
            setVideoPlayerStatus((prevData) => ({ ...prevData, buffering: true}));

          }
             }}
        pip={false}
        onEnded={() => {
          setVideoPlayerStatus({ playing: false, visible: false, alreadyStarted: true ,buffering:false});
    
        
          onEnd();
        }}
        onDuration={(duration)=>{
setDuration(duration)
        }}
        onBuffer={() => {
          setVideoPlayerStatus((prevData) => ({ ...prevData,playing:false, buffering: true }));
          onBuffer();
        }}
        onBufferEnd={() => {
          setVideoPlayerStatus((prevData) => ({ ...prevData,playing:true, buffering: false }));
        }}
        onProgress={handleProgress}
        onPause={onPaused}
        onSeek={onSeek}
        onPlay={() => {
          setVideoPlayerStatus((prevData) => ({ ...prevData,buffering: true }));
          onStart();}
        
        }
        
        ref={videoRef}
        onError={onError}
        
      />

      {/* Custom Seekbar */}
      <div
        className="relative mt-2 h-2 w-full bg-gray-300 rounded cursor-pointer"
        // onClick={handleSeek}
      >

            {/* Buffer progress */}
            <div
          className="absolute top-0 left-0 h-2 bg-slate-100 rounded-lg"
          style={{ width: `100%` }} // Width as buffered percentage
        ></div>
        {/* Buffer progress */}
        <div
          className="absolute top-0 left-0 h-2 bg-slate-300 rounded-lg"
          style={{ width: `${buffered}%` }} // Width as buffered percentage
        ></div>

        {/* Playback progress */}
        <div
          className="absolute top-0 left-0 h-2  bg-current rounded-lg"
          style={{ width: `${played}%` }} // Width as played percentage
        ></div>

      </div>

      {
        videoStatus !== "completed" && (
          <div className="absolute flex items-center bottom-[5px] w-full justify-between p-4">
            <div
              className="cursor-pointer"
              onClick={handlePlayClick}  // Show loading after play is clicked
            >
              {videoPlayerStatus.playing ? (
                <svg
                  fill="white"
                  width="15px"
                  height="15px"
                  viewBox="0 0 300 300"
                  className="drop-shadow-lg"
                >
                  <path d="M14.22,45.665v186.013c0,25.223,16.711,45.66,37.327,45.66c20.618,0,37.339-20.438,37.339-45.66V45.665 c0-25.211-16.721-45.657-37.339-45.657C30.931,0,14.22,20.454,14.22,45.665z" />
                  <path d="M225.78,0c-20.614,0-37.325,20.446-37.325,45.657V231.67c0,25.223,16.711,45.652,37.325,45.652s37.338-20.43,37.338-45.652 V45.665C263.109,20.454,246.394,0,225.78,0z" />
                </svg>
              ) : (
                <svg
                  width="15"
                  height="18"
                  viewBox="0 0 15 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="drop-shadow-lg"
                >
                  <path
                    d="M15 9.12646L1.44431e-05 17.7867L1.52002e-05 0.46621L15 9.12646Z"
                    fill="white"
                  />
                </svg>
              )}
            </div>

            <div
              className="cursor-pointer"
              onClick={() => {
                videoRef.current?.seekTo(0);
                setVideoPlayerStatus({ playing: true, visible: false, alreadyStarted: true });
              }}
            >
              <svg
                width={16}
                height={22}
                viewBox="0 0 16 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="drop-shadow-lg"
              >
                <g clipPath="url(#clip0_121_3299)">
                  <path
                    d="M2.28129 16.5133C3.51839 17.8416 5.1934 18.5732 6.99805 18.5732C10.568 18.5732 13.4724 15.639 13.4724 12.0326C13.4724 8.4263 10.5679 5.49212 6.99805 5.49212C6.05178 5.49212 5.13421 5.69742 4.29675 6.08615L6.62541 6.73888C7.298 6.92737 7.69201 7.63105 7.50533 8.31052C7.35004 8.87582 6.84186 9.24613 6.28817 9.24613C6.17632 9.24613 6.0626 9.23113 5.9495 9.19952L0.925988 7.79145C0.603145 7.70095 0.328938 7.48446 0.163807 7.18975C-0.00105667 6.89504 -0.0435238 6.54614 0.0460606 6.21973L1.4626 1.06235C1.64919 0.382883 2.34575 -0.0151517 3.01834 0.173434C3.69094 0.361929 4.08495 1.0656 3.89827 1.74507L3.35737 3.7142C4.49076 3.20751 5.72536 2.93872 6.99796 2.93872C11.9618 2.93872 16 7.01814 16 12.0326C16 17.0471 11.9618 21.1266 6.99805 21.1266C4.52554 21.1266 2.1352 20.0827 0.440428 18.2627C-0.0378914 17.7493 -0.013485 16.9412 0.494965 16.4582C1.00323 15.975 1.80341 15.9993 2.28129 16.5133Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_121_3299)">
                    <rect
                      width={16}
                      height={21}
                      fill="white"
                      transform="matrix(-1 0 0 1 16 0.126465)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default VideoPlayer;
